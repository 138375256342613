<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <div class="background">
    <div>

      <div style="float: left;width: 2.98rem; height: 10.8rem;background-color: #3C3E3E">
        <el-menu
            :uniqueOpened="true"
            background-color="#3C3E3E"
            text-color="#ffffff"
            active-text-color="#ffffff"
            class="menu"
            :collapse-transition="false"
            :router="true">
          <el-menu-item-group>
            <div class="companyLogo" @click="this.$router.push('/Home');"/>
          </el-menu-item-group>
          <el-submenu index="1">
            <template #title>
              <div  style="text-align: center; font-size: .25rem; font-weight: 500">项目管理</div>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/PersonalAttend"><div style="font-size: .2rem; font-weight: 300">参与的项目</div></el-menu-item>
              <el-menu-item index="/PersonalTender"><div style="font-size: .2rem; font-weight: 300">投标的项目</div></el-menu-item>
              <el-menu-item index="/PersonalCollected"><div style="font-size: .2rem; font-weight: 300">收藏的项目</div></el-menu-item>
              <el-menu-item index="/PersonalWinBid"><div style="font-size: .2rem; font-weight: 300">中标的项目</div></el-menu-item>
              <el-menu-item index="/PersonalAdd"><div style="font-size: .2rem; font-weight: 300">添加作品</div></el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="4">
            <template #title>
              <div  style="text-align: center; font-size: .25rem; font-weight: 500">账号设置</div>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/PersonalInformation"><div style="font-size: .2rem; font-weight: 300">个人信息</div></el-menu-item>
              <el-menu-item index="/DesignCertification"><div style="font-size: .2rem; font-weight: 300">认证</div></el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>

      <div class="header">
        <div class="header_left">
          <i style="font-size:.24rem;cursor:pointer;" class="fa">&#xf0c9</i>
          <p>工作台</p>
        </div>
        <div class="header_right">
          <i class="fa fa-envelope-o" style="font-size:.24rem;"></i>
          <div class="user_tx"></div>
          <div class="user_name"><div style="width: 20px">{{userInfo.username}}</div></div>
<!--          <i class="fa fa-angle-down" style="font-size:.24rem"></i>-->
        </div>
      </div>
      <div class="test">
        <router-view></router-view>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "PersonalBackHome",
  data(){
    return{
      userInfo:{
        username:''
      },
    }
  },
  mounted() {
    this.userInfo.username = window.sessionStorage.getItem("username");
  },
  methods:{
    getUserInfo(){

    }
  }
}
</script>

<style scoped>
.background{
  float: left;
  width: 19.2rem;
  height: 100%;
  background-color: rgb(244,244,244);
}

.header{
  /*float: left;*/
  width: 19.2rem;
  height: .9rem;
  background-color: #ffffff;
  border: .01rem solid transparent;
}

.header .header_left{
  float: left;
  width: 1.24rem;
  height: .24rem;
  margin-left: .33rem;
  margin-right: 11.4rem;
  margin-top: .4rem;
}

.header .header_left i{
  display: inline-block;
  width: .24rem;
  height: .24rem;
  margin-right: .4rem;
}

.header .header_left p{
  display: inline-block;
  width: .6rem;
  height: .24rem;
  font-size: .2rem;
  color:#212121;
}

.header .header_right{
  float: right;
  width: 2.22rem;
  height: .5rem;
  line-height: .5rem;
  margin-top: .25rem;
  margin-right: .65rem;
}

.header .header_right .fa-envelope-o{
  display: block;
  float: left;
  margin: .13rem .2rem 0 0;
  /*margin-right: .2rem;*/
}

.header .header_right .fa-angle-down{
  display: block;
  float: left;
  margin: .13rem 0 .13rem 0;
}

.header .header_right .user_tx{
  float: left;
  width: .5rem;
  height: .5rem;
  margin-right: .1rem;
  border-radius: 50%;
  background-image: url("../../../images/tx.png");
  background-size: 100% 100%;
}

.header .header_right .user_name{
  height: .5rem;
  line-height: .5rem;
  float: left;
  font-size: .2rem;
  margin-right: .1rem;
  color: #212121;
}




.companyLogo{
  width: 3rem;
  height: 1rem;
  background: url("../../../images/head_logo_w.png") no-repeat center;
  background-size: 70%;
  cursor: pointer;
}

.el-menu-item{
  text-align: center;
  font-size: .16rem;
  font-weight: 300;
}

.test {
  width: 16.22rem;
}


</style>
